@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #202225;
  color: #ddd;
  padding: 20px;
}
.dropzone{
  border: 2px dashed rgba(255,255,255,0.2);
  border-radius: 10px;
  padding: 30px 0;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255,255,255,0.6);
}
.dropzone.active{
  border-color:#fff;
}
.file{
  text-decoration:none;
  color: rgba(255, 255, 255, 0.8);
  display:block;
  background-color: #2f3136;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  position:relative;
  overflow: hidden;
}
.file .progress {
  background-color: rgba(54,57,63, .8);
  position: absolute;
  inset:0;
  width:0;
  padding-top: 10px;
  text-align: center;
}
.file .progress.done{
  top: 100px;
}
.file.done {
  background-color: rgb(148 163 184)
}